/* =================================== */
/*  3. Layouts
/* =================================== */

#main-wrapper {
  background:#fff;
}

.section {
  position: relative;
  padding: 104px 0;
  padding: 6.5rem 0;
  overflow:hidden;
}

@include media-breakpoint-down(sm) {
	.section {
		padding: 3.5rem 0;
	}
}

/*== Fullscreen Height ==*/
.fullscreen{
	min-height: 100vh!important;
}

/*== Fullscreen Height ==*/
.fullscreen-with-header{
	min-height: calc(100vh - 67px)!important;
}